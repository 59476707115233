import Api from '@/services/Index'

const getAll = (config) => {
    Api.defaults.baseURL = process.env.VUE_APP_ENDPOINT_STUDENT
    return Api.get('/makeup/exams', config)
}

const getInstructor = async () => {
    Api.defaults.baseURL = process.env.VUE_APP_ENDPOINT_STUDENT
    return Api.get('/makeup/exam/instructor')
}

const getStudent = () => {
    Api.defaults.baseURL = process.env.VUE_APP_ENDPOINT_STUDENT
    return Api.get('/makeup/exam/student')
}

const storePublishDegree = async () => {
    Api.defaults.baseURL = process.env.VUE_APP_ENDPOINT_STUDENT
    return Api.get('/makeup/exam/degree/publish')
}

const storePublish = async () => {
    Api.defaults.baseURL = process.env.VUE_APP_ENDPOINT_STUDENT
    return Api.get('makeup/exam/publish')
}

const calculate = async () => {
    Api.defaults.baseURL = process.env.VUE_APP_ENDPOINT_STUDENT
    return Api.get('makeup/exam/calculate')
}

const calculateDegree = async () => {
    Api.defaults.baseURL = process.env.VUE_APP_ENDPOINT_STUDENT
    return Api.get('makeup/exam/degree/calculate')
}

const storeEnteredLetterGrade = (formData) => {
    Api.defaults.baseURL = process.env.VUE_APP_ENDPOINT_STUDENT
    return Api.post('makeup/exam/entry', formData)
}

const entryLetterGradeStore = async (formData) => {
    Api.defaults.baseURL = process.env.VUE_APP_ENDPOINT_STUDENT
    return Api.post('/makeup/exam/entry', formData)
}

const deleteById = async (id) => {
    Api.defaults.baseURL = process.env.VUE_APP_ENDPOINT_STUDENT
    return Api.delete('/makeup/exams/' + id)
}

export default {
    getAll,
    getStudent,
    getInstructor,
    storePublish,
    storePublishDegree,
    calculate,
    calculateDegree,
    storeEnteredLetterGrade,
    entryLetterGradeStore,
    deleteById
}